var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fill-width"},[_c('button',{staticStyle:{"padding":"10px","display":"none"},attrs:{"id":"synaps-btn"}},[_vm._v("Submit KYC")]),(!_vm.walletStore.walletConnected)?_c('v-btn',{staticClass:"algo-btn btnA full-width d-flex flex-start text-none",class:{
      'button-50 border-radius-5': !_vm.$vuetify.breakpoint.xl,
      'button-60': _vm.$vuetify.breakpoint.xl,
    },attrs:{"loading":_vm.walletStore.connectingWallet},on:{"click":function($event){return _vm.vm.connectWallet()}}},[_vm._v("Connect Wallet ")]):(!_vm.walletStore.isRegisted && !_vm.vm.fetchingData)?_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":"/register"}},[_c('v-btn',{staticClass:"algo-btn btnA full-width d-flex flex-start text-none",class:{
        'button-50 border-radius-5': !_vm.$vuetify.breakpoint.xl,
        'button-60': _vm.$vuetify.breakpoint.xl,
      }},[_vm._v("Register ")])],1):(!_vm.walletStore.isLogin)?_c('v-btn',{staticClass:"algo-btn btnA full-width d-flex flex-start text-none",class:{
      'button-50 border-radius-5': !_vm.$vuetify.breakpoint.xl,
      'button-60': _vm.$vuetify.breakpoint.xl,
    },attrs:{"loading":_vm.walletStore.isLoadingSignIn},on:{"click":function($event){return _vm.walletStore.signIn()}}},[_vm._v("Login ")]):(_vm.vm.isEnableProcessKyc)?_c('v-btn',{staticClass:"algo-btn btnA full-width d-flex flex-start text-none",class:{
      'button-50 border-radius-5': !_vm.$vuetify.breakpoint.xl,
      'button-60': _vm.$vuetify.breakpoint.xl,
    },attrs:{"loading":_vm.walletStore.loadingKycState},on:{"click":function($event){return _vm.submitKyc()}}},[_vm._v("Procced with KYC ")]):(_vm.vm.canRegisterWhitelist)?_c('v-btn',{staticClass:"full-width d-flex flex-start text-none",class:{
      'button-50 border-radius-5': !_vm.$vuetify.breakpoint.xl,
      'button-60': _vm.$vuetify.breakpoint.xl,
      'algo-btn btnA': _vm.vm.isWhitelistStarted,
      'algo-btn btnA--disabled': !_vm.vm.isWhitelistStarted,
    },on:{"click":function($event){return _vm.vm.registrationDialogOnChange(true)}}},[_vm._v("Whitelist Now ")]):(_vm.vm.isClaimStart)?_c('v-btn',{staticClass:"full-width d-flex flex-start text-none",class:{
      'algo-btn btnA': _vm.vm.canClaimToken,
      'algo-btn btnA--disabled': !_vm.vm.canClaimToken,
      'button-50 border-radius-5': !_vm.$vuetify.breakpoint.xl,
      'button-60': _vm.$vuetify.breakpoint.xl,
    },on:{"click":_vm.startClaim}},[_vm._v("Claim ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }