










































































import { Component, Inject, Provide, Ref, Vue, Watch } from 'vue-property-decorator'
import { IdoApplyViewModel } from '../viewmodel/ido-apply-viewmodel'
import { Observer } from 'mobx-vue'
import { walletStore } from '@/stores/wallet-store'

@Observer
@Component({
  components: {},
})
export default class IdoApply extends Vue {
  walletStore = walletStore
  @Inject() vm!: IdoApplyViewModel
  async submitKyc() {
    if (!this.walletStore.synapsClient) {
      await this.walletStore.createSynapsSession()
    }
    document.getElementById('synaps-btn')?.click()
  }
  startClaim() {
    this.$router.push('/allocation')
  }
}
